import React, { useState } from 'react';
import './Login.css';

const Login = ({ onOTPRequest, onOTPVerification }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOTP] = useState('');
  const [otpSent, setOTPSent] = useState(false);

  const handleSendOTP = () => {
    onOTPRequest(phoneNumber);
    setOTPSent(true);
  };

  const handleVerifyOTP = () => {
    onOTPVerification(phoneNumber, otp);
  };

  return (
    <div className="login-container">
      <img src="https://zerotierintegration-3309.twil.io/TCE%20Logo%20-%203%20color-01.svg" alt="TCE Communications Logo" className="login-logo" />
      <h3>Network Operations Management</h3>
      <div className="input-group">
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
          autoFocus
        />
        <button onClick={handleSendOTP}>Send OTP</button>
      </div>
      {otpSent && (
        <div className="input-group">
          <input
            type="text"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
            placeholder="Enter OTP"
          />
          <button onClick={handleVerifyOTP}>Verify OTP</button>
        </div>
      )}
      <footer>
        TCE Communications, Inc. &copy; 2024
      </footer>
    </div>
  );
};

export default Login;
