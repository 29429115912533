import React, { useState } from 'react';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [organization, setOrganization] = useState(null);

  const handleOTPRequest = async (phoneNumber) => {
    try {
      const response = await fetch(process.env.REACT_APP_TWILIO_SEND_OTP_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber }),
      });
      const data = await response.json();
      if (data.success) {
        // OTP sent successfully
        // Implement the logic to show OTP input field
      } else {
        alert(data.error || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP');
    }
  };

  const handleOTPVerification = async (phoneNumber, otp) => {
    try {
      const response = await fetch(process.env.REACT_APP_TWILIO_VERIFY_OTP_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber, otp }),
      });
      const data = await response.json();
      if (data.success) {
        setOrganization(data.organization);
        setIsLoggedIn(true);
      } else {
        alert('Invalid OTP');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Failed to verify OTP');
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <Dashboard organization={organization} />
      ) : (
        <Login onOTPRequest={handleOTPRequest} onOTPVerification={handleOTPVerification} />
      )}
    </div>
  );
};

export default App;
