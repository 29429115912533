import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import axios from 'axios';

const Dashboard = ({ organization }) => {
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [networkId, setNetworkId] = useState('');
  const TOTAL_LICENSES = 125;

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_TWILIO_LIST_NETWORK_MEMBERS_URL);
        const members = response.data.members;
        if (members.length > 0) {
          setNetworkId(members[0].networkId); // Assuming all members have the same networkId
        }
        setMembers(members);
      } catch (error) {
        console.error('Error loading members:', error);
      }
    };
    fetchMembers();
  }, []);

  const sortedMembers = React.useMemo(() => {
    let sortableMembers = [...members];
    if (sortConfig.key !== null) {
      sortableMembers.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // If sorting by 'lastSeen', convert to Date objects for comparison
        if (sortConfig.key === 'lastSeen') {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableMembers;
  }, [members, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = key => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    if (['name', 'description', 'physicalAddress', 'lastSeen'].includes(key)) {
      return '▲▼'; // Neutral indicator
    }
    return null;
  };

  const filteredMembers = sortedMembers.filter(member =>
    member.nodeId.includes(searchTerm) ||
    (member.name && member.name.includes(searchTerm)) ||
    (member.description && member.description.includes(searchTerm)) ||
    (member.config && member.config.ipAssignments && member.config.ipAssignments.join(',').includes(searchTerm)) ||
    (member.physicalAddress && member.physicalAddress.includes(searchTerm)) ||
    (member.lastSeen && new Date(member.lastSeen).toLocaleString().includes(searchTerm))
  );

  const authorizedMembersCount = members.filter(member => member.config.authorized).length;
  const licensesLeft = TOTAL_LICENSES - authorizedMembersCount;

  return (
    <div className="dashboard-container">
      <div className="organization-header">
        <img src={organization.logoUrl} alt={`${organization.name} Logo`} className="organization-logo" />
        <h3>Welcome {organization.name}</h3>
        <h3>Network ID: {networkId}</h3>
        <div className="member-breakdown">
          <p>Members: Joined: {members.length} | Authorized: {authorizedMembersCount} | Licenses left: {licensesLeft} | Total licenses: {TOTAL_LICENSES}</p>
        </div>
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search members..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="member-grid">
        <div className="member-row header">
          <div>Member ID</div>
          <div onClick={() => requestSort('name')}>
            Name {getSortIndicator('name')}
          </div>
          <div onClick={() => requestSort('description')}>
            Description {getSortIndicator('description')}
          </div>
          <div>Authorized</div>
          <div onClick={() => requestSort('physicalAddress')}>
            Public IP {getSortIndicator('physicalAddress')}
          </div>
          <div>Internal IPs</div>
          <div onClick={() => requestSort('lastSeen')}>
            Last Seen {getSortIndicator('lastSeen')}
          </div>
          <div>Action</div>
        </div>
        {filteredMembers.map(member => (
          <div key={member.nodeId} className="member-row" data-id={member.nodeId}>
            <div>{member.nodeId}</div>
            <input type="text" defaultValue={member.name || ''} placeholder="Name" />
            <input type="text" defaultValue={member.description || ''} placeholder="Description" />
            <input type="checkbox" defaultChecked={member.config.authorized} />
            <div>{member.physicalAddress.substring(0, 16)}</div>
            <div>{member.config.ipAssignments.join(', ').substring(0, 16)}</div>
            <div>{new Date(member.lastSeen).toLocaleString().replace(/:\d{2}\s/, ' ')}</div>
            <button
              className="update-button"
              onClick={() => updateMember(member.nodeId)}
              disabled={member.nodeId === '55a3d000bc'}
            >
              Update
            </button>
          </div>
        ))}
      </div>
      <footer>
        TCE Communications, Inc. &copy; {new Date().getFullYear()}
      </footer>
    </div>
  );
};

const updateMember = async (memberId) => {
  const row = document.querySelector(`div[data-id="${memberId}"]`);
  const name = row.querySelector('input[placeholder="Name"]').value;
  const description = row.querySelector('input[placeholder="Description"]').value;
  const authorized = row.querySelector('input[type="checkbox"]').checked;

  if (!name || !description) {
    alert('Name and description cannot be empty.');
    return;
  }

  try {
    const response = await axios.post(process.env.REACT_APP_TWILIO_UPDATE_MEMBER_AUTHORIZATION_URL, {
      memberId,
      name,
      description,
      authorized
    });
    if (response.data.updatedMember) {
      alert('Member updated successfully!');
    } else {
      alert('Failed to update member.');
    }
  } catch (error) {
    console.error('Error updating member:', error);
    alert('Failed to update member.');
  }
};

export default Dashboard;
